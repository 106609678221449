import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { Switch, Route, Redirect } from 'react-router-dom';

import { authAdminSuperAdminPrivate } from './components/hoc/auth-admin-superadmin-private';
import { adminSuperAdminPrivate } from './components/hoc/admin-superadmin-private';
import { superAdminPrivate } from './components/hoc/superadmin-private';
import AdminToolbar from './components/admin/toolbar';
import FooterMenu from './components/footer-menu';
import Popup from './components/common/popup';
import AlertsPage from './pages/alerts';
import AuthorPostsPage from './pages/author-posts';
// ===> admin pages
import CreateCategory from './components/admin/category/create';
import DeleteCategory from './components/admin/category/delete';
import MoveCategory from './components/admin/category/move';
import RenameCategory from './components/admin/category/rename';
import CreateBoard from './components/admin/board/create';
import RenameBoard from './components/admin/board/rename';
import MoveBoard from './components/admin/board/move';
import MergeBoard from './components/admin/board/merge';
import AdditionalBoard from './components/admin/board/additional';
import DeleteBoard from './components/admin/board/delete';
import ManageSpamPage from './components/routes/manage-spam';
import ManageObjections from './components/admin/manage/objections';
import ManageRights from './components/admin/manage/rights';
import ManageBoards from './components/admin/manage/boards';
import AdminList from './components/admin/admin-list';
import NodeOptions from './components/admin/node/options';
import RecycleBin from './components/admin/node/recycle-bin';
// <!====
import HomePage from './pages/home';
import FavoritesPage from './pages/favorites';
import CategoryPage from './pages/category';
import ThreadPage from './pages/thread';
import ThreadEditPage from './pages/thread-edit';
import ThreadCreatePage from './pages/thread-create';
import ThreadReplyPage from './pages/thread-reply';
import BoardInfoEditPage from './pages/board-info-edit';
import NotFoundPage from './pages/404';
import MoveThread from './components/admin/thread/move';
import PrintThreadPage from './components/thread/old_print';
import ReportAbusePage from './pages/report-abuse';
import VolunteerPage from './pages/volunteer';
// ===> search pages
import SearchBoardsPage from './pages/search-boards';
import SearchSurnamePage from './pages/search-surname';
import { AdvancedSearchPage } from './components/searches/advanced';
import SearchResultsPage from './pages/search-results';
// <!====
import { getUserData, loadingSelector } from './ducks/user';
import { isActiveToolbar } from './ducks/admin-panel';
import Progress from './components/common/progress';
import config from './config';

import BoardInfo from './components/board-info';
import RequestBoardsPage from './pages/request-boards';
import m from './messages';

export function Root({ intl }) {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const activeToolbar = useSelector(isActiveToolbar);
  const { institution } = config;

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  if (loading) return <Progress />;
  return (
    <div>
      <Helmet>
        <title>{intl.formatMessage(m.helmet)}</title>
      </Helmet>
      <BoardInfo />
      <Popup />
      {activeToolbar && (
      <div role='region' aria-label='Admin Bar'>
        <AdminToolbar />
      </div>
      )}
      <main>
        <Switch>
          <Route path={["/admin/category/create", "/admin/category/create/:path"]} exact component={superAdminPrivate(CreateCategory)} />
          <Route path="/admin/category/delete/:path" exact component={superAdminPrivate(DeleteCategory)} />
          <Route path="/admin/category/move/:path" exact component={superAdminPrivate(MoveCategory)} />
          <Route path="/admin/category/rename/:path" exact component={superAdminPrivate(RenameCategory)} />
          <Route path="/admin/board/create/:path" exact component={superAdminPrivate(CreateBoard)} />
          <Route path="/admin/board/info/edit/:path" exact component={adminSuperAdminPrivate(BoardInfoEditPage)} />
          <Route path="/admin/board/rename/:path" exact component={superAdminPrivate(RenameBoard)} />
          <Route path="/admin/board/move/:path" exact component={superAdminPrivate(MoveBoard)} />
          <Route path="/admin/board/merge/:path" exact component={superAdminPrivate(MergeBoard)} />
          <Route path="/admin/board/additional/:path" exact component={superAdminPrivate(AdditionalBoard)} />
          <Route path="/admin/board/delete/:path" exact component={superAdminPrivate(DeleteBoard)} />
          <Route path="/admin/manage/spam" exact component={superAdminPrivate(ManageSpamPage)} />
          <Route path="/admin/manage/:name(objections)" exact component={adminSuperAdminPrivate(ManageObjections)} />
          <Route path="/admin/manage/:name(rights)" exact component={superAdminPrivate(ManageRights)} />
          <Route path="/admin/manage/:name(boards)" exact component={superAdminPrivate(ManageBoards)} />
          <Route path={["/admin/list", "/admin/list/:path"]} exact component={superAdminPrivate(AdminList)} />
          <Route path={['/admin/options', '/admin/options/:path']} exact component={superAdminPrivate(NodeOptions)} />
          <Route path="/admin/recycle-bin/:path" exact component={adminSuperAdminPrivate(RecycleBin)} />
          <Route path="/:path/:id/move" exact component={adminSuperAdminPrivate(MoveThread)} />

          <Route path={['/request-board', '/request-board/:path/:id?']} exact component={authAdminSuperAdminPrivate(RequestBoardsPage)} />
          <Route path="/404" exact component={NotFoundPage} />
          <Route path="/" exact component={HomePage} />
          <Route path="/alerts" exact component={authAdminSuperAdminPrivate(AlertsPage)} />
          <Route path='/favorites/:path' exact component={authAdminSuperAdminPrivate(FavoritesPage)} />
          <Route path='/volunteer-admin/:path' exact component={authAdminSuperAdminPrivate(VolunteerPage)} />
          <Route path="/search/results" exact component={SearchResultsPage} />
          <Route path="/search/advanced" exact component={AdvancedSearchPage} />
          <Route path="/search/boards/:query" exact component={SearchBoardsPage} />
          <Route path="/search/surname" exact component={SearchSurnamePage} />
          <Redirect exact from='/surnames' to='/search/surname' />
          {/*
          <!== Message Boards
          */}
          <Route path="/author-posts/:userId" exact component={AuthorPostsPage} />
          <Route path="/:path" exact component={CategoryPage} />
          <Route path="/:path/create" exact component={authAdminSuperAdminPrivate(ThreadCreatePage)} />
          <Route path="/:path/:threadId" exact component={ThreadPage} />
          <Route path="/:path/:threadId/edit" exact component={authAdminSuperAdminPrivate(ThreadEditPage)} />
          <Route path="/:path/:threadId/reply" exact component={authAdminSuperAdminPrivate(ThreadReplyPage)} />
          <Route path="/:path/:id/print" exact component={PrintThreadPage} />
          <Route path="/:path/:id/abuse" exact component={authAdminSuperAdminPrivate(ReportAbusePage)} />
          <Route path="*" exact component={NotFoundPage} />
        </Switch>
      </main>
      { institution
        ? null
        : (
          <footer>
            <FooterMenu />
          </footer>
        )
      }

    </div>
  );
}

export default injectIntl(Root);
