import React, { useState } from 'react';
import PropTypes from 'prop-types';
import m from './messages';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import url from '../../../url-service';



export default function AdminNodes({ adminNodes }) {
  if (!Array.isArray(adminNodes)) return null;

  const ShortListSize = 3;
  const [expanded, setExpanded] = useState(false);
  const nodeListIsLong = adminNodes.length > ShortListSize;
  const nodes = expanded || !nodeListIsLong ? adminNodes : adminNodes.slice(0, ShortListSize);
  const items = nodes.map(item => (
    <li key={item.path}>
      <Link to={url.createPath('category', item.path)}>{item.title}</Link>
      <FormattedMessage {...m.objections} values={{ number: item.objectionCount }} />
    </li>
  ));

  let expandButton;
  if (nodeListIsLong) {
    expandButton = (
      <button type="button" className="link" onClick={() => setExpanded(!expanded)}>
        <FormattedMessage {...(expanded ? m.collapseList : m.expandList)} />
      </button>
    );
  }

  return (
    <div className="admin-nodes">
      <h3>
        <FormattedMessage {...m.administerNodes} values={{ shown: nodes.length, total: (adminNodes || []).length }} />
      </h3>
      <ol>{items}</ol>
      {expandButton}
    </div>
  );
}


AdminNodes.propTypes = {
  adminNodes: PropTypes.array
}

AdminNodes.defaultProps = {
  adminNodes: []
}