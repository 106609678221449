import React from 'react';
import classNames from 'classnames';
import {  bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { isBusy } from '../../ducks/common/busy';
import { spaceless } from '../../utils/strings';

/**
 *
 * @param {boolean} canSubmit
 * @param {function}  submit
 * @param {string}  classes
 * @param {Element|array}  children
 * @returns {Element}
 * @constructor
 */
export function Form({ canSubmit = true, submit, classes = '', children }) {
  function onKeyPress(e) {
    // if Enter pressed
    if (e.keyCode === 13) {
      submit();
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    submit();
    return false;
  }

  const className = classNames(spaceless(`widget form ${classes}`), {
    'can-submit': canSubmit,
    'cannot-submit': !canSubmit
  });

  return (
    <form
      onSubmit={onSubmit}
      onKeyPress={onKeyPress}
      className={className}
    >
      {children}
    </form>
  );
}

Form.propTypes = {
  canSubmit: bool,
  // children: ChildrenType.isRequired,
  classes: string,
  submit: func.isRequired
};

Form.defaultProps = {
  canSubmit: true,
  classes: ''
};

function mapStateToProps(state, props) {
  return {
    canSubmit: 'canSubmit' in props ? props.canSubmit : isBusy(state)
  };
}

export default connect(mapStateToProps)(Form);
