import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import m from './messages';
import { Link } from 'react-router-dom';

export default function ErrorView({ error, message, classModificator }) {

  function renderMovedTemplate() {
    return (
      <div className="error-view__content">
        <FormattedMessage {...m.movedThreadPartOne} />
        <FormattedMessage {...m.movedThreadPartTwo} />
        {error.link && error.linkSubject && <div className="error-view__content-link">
          <FormattedMessage {...m.movedThreadPartThree} />
          <Link className="content-link__title" to={error.link}>
            {error.linkSubject}
          </Link>
        </div>}
      </div>
    )
  }

  function getErrorMessage() {
    if (message) return message;
    if (error && error.movedThread) return renderMovedTemplate();
    if (error && error.messageKey) return <FormattedMessage {...m[error.messageKey]} />;

    const messageKey = 'default';
    return <FormattedMessage {...m[messageKey]} />

  }

  return (
    <div className={`error-view${ classModificator ? ` error-view--${classModificator}`: '' }`}>
      {getErrorMessage()}
    </div>
  )
}

ErrorView.propTypes = {
  error: PropTypes.object,
  message: PropTypes.node // message is formatted messages from react-intl
}
